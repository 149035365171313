import { useEffect, useState } from "react";

import classNames from "classnames";

import REQUESTS from "../../../api/requests";

import styles from "../index.module.scss";
import Text from "../../../components/Text";

export default function DownloadApps() {
    const [data, setData] = useState([]);

    const getApplications = () => {
        REQUESTS.APPLICATIONS((data) => setData(data.rows));
    }

    useEffect(() => {
        getApplications();
    }, []);

    return (
        <section className={styles['download-apps-section']}>
            <div className={classNames("wrapper", styles['apps-content'])}>
                <h2 className={styles['download-title']}><Text page="home" text="Download_for_free" /></h2>
                <div className={styles['apps-container']}>
                    {data.map((item, index) => {
                        const { title, description, file } = item || {};
                        return <div key={index} className={styles['download-app-block']}>
                            <h5 className={styles['app-title']}>{title}</h5>
                            <div dangerouslySetInnerHTML={{ __html: description }} className={styles['app-description']} />
                            <a href={file} target="_blank" download={file} className={styles['app-download-file-btn']}><Text page="home" text="Download" /></a>
                        </div>
                    })}
                </div>
            </div>
        </section>
    )
}
