import HOME_IMAGE_1 from '../assets/images/home-section-1-left.png';
import HOME_IMAGE_2 from '../assets/images/home-section-1-right.png';
import HOME_IMAGE_3 from '../assets/images/home-section-2-left.png';
import HOME_IMAGE_4 from '../assets/images/home-section-2-right.png';
import HOME_IMAGE_5 from '../assets/images/home-section-3-left.png';
import HOME_IMAGE_6 from '../assets/images/home-section-3-right.png';

import TELEGRAM from '../assets/images/telegram.png';
import EMAIL from '../assets/images/email.png';

const IMAGES = {
    HOME_IMAGE_1,
    HOME_IMAGE_2,
    HOME_IMAGE_3,
    HOME_IMAGE_4,
    HOME_IMAGE_5,
    HOME_IMAGE_6,
    TELEGRAM,
    EMAIL
};

export default IMAGES