
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import useTranslation from '../../../hooks/useTranslation';

import TEXT from '../../../configs/text';

import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Message from '../../../components/Message';

import styles from "../styles/Modals.module.scss";

export default function PinModal({ open, onClose, message, onSubmit = () => { } }) {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.playlist?.[text]) {
            return translation?.pages?.playlist?.[text]
        }

        return TEXT?.pages?.playlist?.[text];
    };

    return open && <Formik
        initialValues={{
            pin: ""
        }}
        validationSchema={Yup.object({
            pin: Yup.string().required(handleText("Please input PIN"))
        })}
        onSubmit={(values, { resetForm }) => {
            onSubmit(values.pin);
            resetForm();
        }}
    >
        {
            ({ resetForm, errors, touched }) => {
                return <Modal
                    open={open}
                    onClose={() => {
                        onClose();
                        resetForm();
                    }}
                >
                    <Form autoComplete='off' >
                        <p className={styles["modal-text"]}>
                            {handleText("Enter your PIN")}
                        </p>
                        <Input
                            type="password"
                            name="pin"
                            message={{
                                text: touched.pin && errors.pin,
                                type: false
                            }}
                        />
                        <div className={styles['err-message-container']}><Message message={message} style={{ fontSize: 16 }} /></div>
                        <div className={styles['btns-container']}><Button type='submit'>{handleText("Enter")}</Button></div>
                    </Form>
                </Modal>
            }
        }
    </Formik>
}
