
import classNames from "classnames";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useField } from 'formik';

import Message from "./Message";

import styles from "./styles/Input.module.scss";

export default function PhoneInputComponent({ ...props }) {
    const [field, meta, helpers] = useField({ name: "phone", ...props });

    const handlePhoneChange = (value) => {
        helpers.setValue(value || "");  // Update the Formik value
        helpers.setTouched(true);  // Set the field as touched
    };

    return (
        <div className={classNames(styles["input-container"], { [styles['error']]: meta.touched && meta?.error?.length })}>
            <PhoneInput
                international
                defaultCountry="US"
                value={field.value}
                onChange={handlePhoneChange}
                style={{ width: "100%" }}
                className={styles["phone-input"]}
            />

            <Message message={{ text: meta.touched && meta?.error, type: false }} />
        </div >
    );
}
