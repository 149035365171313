import { useContext, useEffect, useState } from "react";

import classNames from "classnames";

import Modal from "../../../components/Modal";

import PlaylistByUrl from "./PlaylistByUrl";
import PlaylistByXtream from "./PlaylistByXtream";
import PlaylistByFile from "./PlaylistByFile";

import { Context } from "../index";

import styles from "../styles/PlaylistModal.module.scss";

export default function PlaylistModal({ playlist, open, onClose = () => { }, mac }) {
    const [activeTab, setActiveTab] = useState("Url");

    const { getPlaylists } = useContext(Context);

    useEffect(() => {
        if (!open) {
            setActiveTab("Url");
        }
    }, [open]);

    return open && <Modal
        open={open}
        onClose={onClose}
    >
        <div className={styles['modal-content']}>
            <h4 className={styles['modal-title']}>{playlist ? "Edit" : "Add"} playlist</h4>
            {
                playlist ? < PlaylistByUrl
                    playlist={playlist}
                    mac={mac}
                    getPlaylists={() => {
                        getPlaylists();
                        onClose();
                    }}
                /> :
                    <> <div className={styles['tabs']}>
                        <div className={classNames(styles['tab'], { [styles['active']]: activeTab == "Url" })} onClick={() => setActiveTab("Url")}>Url</div>
                        <div className={classNames(styles['tab'], { [styles['active']]: activeTab == "Xtream account" })} onClick={() => setActiveTab("Xtream account")}>Xtream account</div>
                        <div className={classNames(styles['tab'], { [styles['active']]: activeTab == "Other M3U8" })} onClick={() => setActiveTab("Other M3U8")}>Other M3U8</div>
                        <div className={classNames(styles['tab'], { [styles['active']]: activeTab == "File" })} onClick={() => setActiveTab("File")}>File</div>
                    </div>
                        {(() => {
                            switch (activeTab) {
                                case "Url":
                                    return < PlaylistByUrl
                                        playlist={playlist}
                                        mac={mac}
                                        getPlaylists={() => {
                                            getPlaylists();
                                            onClose();
                                        }}
                                    />
                                case "Xtream account":
                                    return <PlaylistByXtream
                                        mac={mac}
                                        getPlaylists={() => {
                                            getPlaylists();
                                            onClose();
                                        }} />
                                case "Other M3U8":
                                    return <PlaylistByUrl
                                        mac={mac}
                                        getPlaylists={() => {
                                            getPlaylists();
                                            onClose();
                                        }} />
                                case "File":
                                    return <PlaylistByFile
                                        mac={mac}
                                        getPlaylists={() => {
                                            getPlaylists();
                                            onClose();
                                        }}
                                    />
                            }
                        })()}
                    </>
            }
        </div>
    </Modal>
}
