
import { Link } from "react-router-dom";

import classNames from "classnames";

import img from "../../assets/images/construction.png";

import ICONS from "../../configs/icons";

import Button from "../../components/Button"

import styles from "./index.module.scss";

export default function UnderConstruction() {
    return (
        <div className={styles['page']}>
            <div className={classNames(styles['content'], "wrapper")}>
                <h1 className={styles['title']}>Website is Under Construction</h1>
                <p className={styles['text']}>This Page doesn`t exist or was removed!
                    We suggest you  back to home.</p>
                <Link to="/" className={styles['btn']}><Button>{ICONS.BACK}Back to Home</Button></Link>
                <img alt="under construction" src={img} className={styles['img']} />
            </div>
        </div>
    )
}
