
import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import REQUESTS from '../../../api/requests';

import dateFormat from '../../../utils/dateFormat';

import useResize from '../../../hooks/useResize';

import News from './News';
import NewsSlider from './NewsSlider';

import Text from "../../../components/Text";

import styles from "../styles /Current.module.scss";

export default function CurrentNews() {
    const [currentNews, setCurrentNews] = useState(null);
    const [news, setNews] = useState([]);

    const resize = useResize();

    const { id } = useParams();

    const getCurrentNews = () => {
        REQUESTS.GET_CURRENT_NEWS(
            id,
            (data) => {
                setCurrentNews(data.rows[0]);
            });
    };

    const getRelatedNews = () => {
        REQUESTS.GET_NEWS(
            (data) => {
                let newsData = data?.rows?.filter((item) => item.id != id);
                setNews(data?.rows);
            },
        );
    };

    useEffect(() => {
        if (!id) return;

        getRelatedNews();
        getCurrentNews();
    }, [id]);

    return (
        <div className={styles['current-page']}>
            <div className={"wrapper"}>
                {currentNews &&
                    <div className={styles['current-news']}>
                        <div>{currentNews.image && <img src={currentNews.image} alt="news" className={styles['news-image']} />}</div>
                        <div className={styles['news-content']}>
                            <time className={styles['time']}>{currentNews.createdAt && dateFormat(currentNews.createdAt)}</time>
                            <h3 className={styles['title']}>{currentNews.title}</h3>
                            <div className={styles['description']} dangerouslySetInnerHTML={{ __html: currentNews.description }} />
                            <div className={styles['description']}>
                            </div>
                        </div>
                    </div>
                }

                <h2 className={styles['subtitle']}><Text page="current_news" text="subtitle" /> </h2>

                {resize < 830 ? <News news={news} /> : <NewsSlider news={news?.filter((item) => item.id != id)} />}
            </div>
        </div>
    )
}
