import { useContext } from 'react';

import ICONS from '../../../configs/icons';

import useResize from '../../../hooks/useResize';

import Actions from './Actions';

import { Context } from '../index';

import styles from "../styles/Playlists.module.scss";

export default function PlaylistItem({ playlist }) {
    const { openDropdown, setOpenDropdown, modalAction } = useContext(Context);

    const { name, url, id } = playlist;

    const resize = useResize();

    return <div className={styles['container']}>
        <div className={styles['playlist-item']}>
            <p className={styles['name']}>{name}</p>
            <div className={styles['url-container']}>
                <span className={styles['url']}>{url}</span>
                {
                    resize > 980 ?
                        <span className={styles['dots']} onClick={(e) => { e.stopPropagation(); setOpenDropdown(id); }}>{ICONS.DOTS}</span>
                        :
                        <div className={styles['edit-delete']}>
                            <div onClick={() => modalAction("edit", playlist)}>{ICONS.EDIT}</div>
                            <div onClick={() => modalAction("delete", playlist)}>{ICONS.TRASH}</div>
                        </div>
                }
            </div>
        </div>
        <Actions open={openDropdown == id} playlist={playlist} />
    </div>
}