import { useState } from "react";

import { Form, Formik, } from "formik";
import * as Yup from 'yup';

import classNames from "classnames";

import useTranslation from "../hooks/useTranslation";

import TEXT from "../configs/text";

import REQUESTS from "../api/requests";

import Button from "./Button";
import Input from "./Input";
import MessageModal from "./MessageModal";

import styles from "./styles/ContactUsForm.module.scss";

export default function ContactUsForm({ }) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        text: '',
        type: false
    });

    const translation = useTranslation();

    const validationSchema = Yup.object({
        title: Yup.string().required(getTranslation("title_input_message")),
        email: Yup.string().email(getTranslation("email_input_validation_message")).required(getTranslation("contact_us", "email_input_message")),
        message: Yup.string().required(getTranslation("message_input_message"))
    });

    function getTranslation(text) {
        if (translation?.pages?.contact_us?.[text]) {
            return translation?.pages?.contact_us?.[text];
        }
        return TEXT?.pages?.contact_us?.[text];
    }

    const onSubmit = (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        setMessage({
            text: '',
            type: false
        });

        const body = {
            title: values.title,
            email: values.email,
            message: values.message,
        };

        function callback() {
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setMessage({
                text: getTranslation("submition_success_message"),
                type: true
            })
        }

        function errorCallback() {
            setLoading(false);
            setMessage({
                text: getTranslation("error_message"),
                type: false
            })
        }

        REQUESTS.SEND_EMAIL(body, callback, errorCallback);
    };

    return <>
        <Formik
            initialValues={{ title: '', email: '', message: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >
            {props => {
                const {
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                } = props;

                return <Form autoComplete="off">
                    <div className={classNames(styles['footer-form'])}>
                        <Input
                            name="title"
                            placeholder={getTranslation("title_input_placeholder")}
                            message={{
                                text: touched.title && errors.title,
                                type: false
                            }}
                        />
                        <Input
                            name="email"
                            placeholder={getTranslation("email_input_placeholder")}
                            message={{
                                text: touched.email && errors.email,
                                type: false
                            }}
                        />
                        <Input
                            name="message"
                            placeholder={getTranslation("message_input_placeholder")}
                            message={{
                                text: touched.message && errors.message,
                                type: false
                            }}
                        />
                        <Button type="submit" loading={loading} disabled={isSubmitting} styleType="white">{getTranslation("button_title")}</Button>
                    </div>
                </Form>
            }}
        </Formik>
        <MessageModal
            open={message.text}
            onClose={() => setMessage({ text: '', type: false })}
            message={message}
        />
    </>
}
