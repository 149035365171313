
import classNames from "classnames";

import Modal from "../../components/Modal";

import styles from "../styles/LogoutPopup.module.scss";
import Text from "../../components/Text";

export default function LogoutConfirmPopup({ open, onClose, onConfirm }) {
    return (
        <Modal open={open} onClose={onClose}>
            <div className={styles['content']}>
                <h2 className={styles['title']}><Text page="playlist" text="Are you sure you want to log out?" /></h2>
                <div className={styles['btns']}>
                    <button onClick={onClose} className={classNames(styles['cancel-btn'], styles['btn'])}><Text page="playlist" text="Cancel" /></button>
                    <button onClick={onConfirm} className={classNames(styles['ok-btn'], styles['btn'])}><Text page="playlist" text="Log out" /></button>
                </div>
            </div>
        </Modal>
    )
}
