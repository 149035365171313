import { createSlice } from '@reduxjs/toolkit';
import { HOST } from '../../configs';

const initialState = {
    "icon": `${HOST}flag/us.png`,
    "id": 1,
    "name": "English",
    "iso_code": "en",
    "is_default": true,
    "is_active": true,
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            return action.payload;
        },
    },
})

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
