import { Link } from 'react-router-dom';

import Button from '../../../components/Button';

import Text from '../../../components/Text';

import img from "../../../assets/images/home-header-img.png";

import styles from "../index.module.scss";
import classNames from 'classnames';

export default function HeaderSection() {
    return (
        <section className={styles['home-header-section']}>
            <div className='wrapper'>
                <div>
                    <h1 className={styles['title']}><Text page="home" text="header_title" /></h1>
                    <p className={styles['description']}><Text page="home" text="header_second_title" /></p>
                    <p className={styles['text']}><Text page="home" text="header_description" /></p>
                    <div className={styles["buttons"]}>
                        <Link to="/activation"><Button><Text page="home" text="header_activate_app_button" /></Button></Link>
                        <Link to="/login"><Button styleType='grey'><Text page="home" text="header_upload_playlist_button" /></Button></Link>
                    </div>
                </div>
                <img src={img} alt='macbook image' className={styles['header-image']} />
            </div>
        </section >
    )
}
