import React from 'react'

import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import ICONS from '../../configs/icons';
import IMAGES from '../../configs/images';

import Text from '../../components/Text';

import ContactUsForm from '../../components/ContactUsForm';

import styles from "../styles/Footer.module.scss";

export default function Footer() {
    const location = useLocation();

    const menuItemClassName = (path) => {
        return classNames(
            styles['menu-item'],
            {
                [styles['active']]: location.pathname === path
            })
    }

    return (
        <footer className={styles['footer']}>
            <div className={classNames('wrapper', styles['footer-content'])}>

                <Link to="/" className={styles['logo']} >{ICONS.LOGO_NAME}</Link>

                <div>
                    <ul className={styles['menu-list']}>
                        <li className={menuItemClassName("/")}><Link to="/"><Text page="home" text="page_name" /></Link></li>
                        <li className={menuItemClassName("/activation")}><Link to="/activation"><Text page="activation" text="page_name" /></Link></li>
                        <li className={menuItemClassName("/news")}><Link to="/news"><Text page="news" text="page_name" /></Link></li>
                        <li className={menuItemClassName("/reseller")}><Link to="/reseller"><Text page="reseller" text="page_name" /></Link></li>
                    </ul>
                    <div className={styles['social-links']}>
                        <Link to='https://t.me/RelaxPlaySupport' target='_blank'>
                            <img src={IMAGES.TELEGRAM} alt='telegram icon' className={styles['social-icon-img']} />
                        </Link>
                        <a href="mailto:relaxplay@admin.com">
                            <img src={IMAGES.EMAIL} alt="email icon" className={styles['social-icon-img']} />
                        </a>
                    </div>
                </div>


                <div className={styles['contact-us-form']}>
                    <h5 className={styles['contact-us-title']}><Text page="contact_us" text="page_name" /></h5>
                    <ContactUsForm />
                    <div className={styles['bottom']}>
                        <Link to='https://t.me/RelaxPlaySupport' target='_blank'>
                            <img src={IMAGES.TELEGRAM} alt='telegram icon' className={styles['social-icon-img']} />
                        </Link>
                        <a href="mailto:relaxplay@admin.com">
                            <img src={IMAGES.EMAIL} alt="email icon" className={styles['social-icon-img']} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles['footer-bottom']}>
                <div className={classNames('wrapper', styles['footer-bottom-content'])}>
                    <Link to='privacy-policy'><Text page="privacy" text="page_name" /></Link>
                    <div>©2024 | All Rights Reserved</div>
                </div>
            </div>
        </footer>
    )
}
