import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

import { HOST } from "../../../configs";

import Loading from "../../../components/Loading";

import styles from "../index.module.scss"

export default function StripePaymentForm() {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${HOST}payment/stripe/success`,
            },
        });

        if (result.error) {
            navigate("/payment-error");
        }
    };

    return (
        <div className={styles['stripe-form-container']}>
            {loading && (
                <div className={styles.loading}>
                    <Loading style={{ color: "#fff" }} />
                </div>
            )}
            <form onSubmit={handleSubmit} className={styles['stripe-form']} style={{ opacity: loading ? 0 : 1 }}>
                <PaymentElement onReady={() => setLoading(false)} />
                <button
                    disabled={!stripe || loading}
                    className={styles["stripe-form-submit-button"]}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
