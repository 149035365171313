import React, { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import classNames from 'classnames';

import ICONS from '../configs/icons';

import styles from './styles/Modal.module.scss';

export default function Modal({ open, onClose, children }) {

  const [show, setShow] = useState(false);

  function handleClose(e) {
    if (e.target.classList.contains(styles["modal-mask"])) {
      onClose();
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {

    if (open) {
      setShow(true);
    } else {
      setShow(false);
    }

    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('click', handleClose);

    return () => {
      window.removeEventListener('click', handleClose);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [open]);

  return createPortal(
    <div className={classNames(styles["modal-mask"], { [styles["show"]]: show })} >
      <div className={classNames(styles['modal'], { [styles["show"]]: show })} >
        <div onClick={onClose} className={styles['close-icon']}>{ICONS.CLOSE}</div>
        {children}
      </div>
    </div>,
    document.body
  )
}
