import { useState } from "react";

import { Field } from "formik";

import classNames from "classnames";

import ICONS from "../configs/icons";

import Message from './Message';

import styles from "./styles/Input.module.scss";

export default function Input({ type = "text", label = "", message, ...props }) {
    const [showPassword, setShowPassword] = useState(false);

    switch (type) {
        case "checkbox":
            return <label >
                <Field type="checkbox" {...props} />{" "}
                {label}
            </label>
        case "password":
            return <div className={classNames(styles["input-container"], { [styles['error']]: message?.text?.length })}>
                <Field type={showPassword ? "text" : "password"} {...props} />
                <div className={styles['eye-icon']}
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? ICONS.SHOW_EYE : ICONS.HIDE_EYE}
                </div>
                <Message message={message} />
            </div>
        case "textarea":
            return <div className={classNames(styles["input-container"], styles['textarea'], { [styles['error']]: message?.text?.length })}>
                <Field as="textarea" {...props} />
                <Message message={message} />
            </div>
        case "text":
            return <div className={classNames(styles["input-container"], { [styles['error']]: message?.text?.length })}>
                <Field type="text" {...props} />
                <Message message={message} />
            </div>
        default:
            return <div className={classNames(styles["input-container"], { [styles['error']]: message?.text?.length })}>
                <Field type="text" {...props} />
                <Message message={message} />
            </div>
    }

}
