
import PlaylistItem from "./PlaylistItem";

import styles from "../styles/Playlists.module.scss";

export default function Playlists({ playlists }) {

    return (
        <div className={styles['playlists']}>
            {playlists.map((playlist, index) => <PlaylistItem
                key={index}
                playlist={playlist}
            />)}
        </div>
    )
}
