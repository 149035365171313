import { createPortal } from 'react-dom';

import classNames from 'classnames';

import ICONS from '../configs/icons';
import Button from './Button';

import styles from "./styles/Modal.module.scss";
import { useEffect } from 'react';
import Modal from './Modal';

export default function MessageModal({ message, open, onClose = () => { } }) {

    function handleClose(e) {
        if (e.target.classList.contains(styles["modal-mask"])) {
            onClose();
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Escape') {
            onClose();
        }
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        document.addEventListener('keydown', handleKeyDown);

        window.addEventListener('click', handleClose);

        return () => {
            window.removeEventListener('click', handleClose);
            document.removeEventListener('keydown', () => { });
        }

    }, [open]);

    return <Modal open={open} onClose={onClose}>
        <div className={styles['icon']}>{message.type ? ICONS.SUCCESS_CHECK : ICONS.CLOSE_ICON}</div>
        <p className={styles['message']}>{message.text}</p>
        <div className={styles['close-btn']}><Button onClick={onClose}>Close</Button></div>
    </Modal>
}
