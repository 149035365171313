import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

const appInfoSlice = createSlice({
    name: 'webSettings',
    initialState,
    reducers: {
        setAppInfo: (state, action) => {
            return action.payload;
        },
    },
})

export const { setAppInfo } = appInfoSlice.actions;

export default appInfoSlice.reducer;