
import LastNews from './components/LastNews';
import Sections from './components/Sections';
import DownloadApps from './components/DownloadApps';
import HeaderSection from './components/HeaderSection';
import SectionsResponsive from './components/SectionResponsive';

import IMAGES from '../../configs/images';

import useResize from "../../hooks/useResize";

const sections = [
    {
        title: "Launch Your Relax Play In 7 Days",
        description: "With Relax Play you can use all modern functional lists of the players...",
        img_left: IMAGES.HOME_IMAGE_1,
        img_right: IMAGES.HOME_IMAGE_2,
        translation_title_key: "section_one_title",
        translation_description_key: "section_one_description",
    },
    {
        title: "Watch Live TV with comfort",
        description: "Our good organized UI will bring you a new level of watching live tv programs.Pick a channel, Watch, Save to favorites, and the app will start suggesting to you what you like to watch.",
        img_left: IMAGES.HOME_IMAGE_3,
        img_right: IMAGES.HOME_IMAGE_4,
        translation_title_key: "section_two_title",
        translation_description_key: "section_two_description",
    },
    {
        title: "Find content faster",
        description: "A single place to search for your Movies, Tv shows, or LiveTV. Search and start instantly your watch session without 0 lose of your time.",
        img_left: IMAGES.HOME_IMAGE_5,
        img_right: IMAGES.HOME_IMAGE_6,
        translation_title_key: "section_three_title",
        translation_description_key: "section_three_description",
    },
];

export default function HomePage() {
    const resize = useResize();

    return (
        <>
            <HeaderSection />
            <DownloadApps />
            {resize < 1000 ? <SectionsResponsive sections={sections} /> : <Sections sections={sections} />}
            <LastNews />
        </>
    )
}
