
import React, { createContext, useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

import dateFormat from "../../utils/dateFormat";

import ICONS from "../../configs/icons";

import Text from "../../components/Text";
import Button from "../../components/Button";

import PinModal from "./components/PinModal";
import Playlists from "./components/Playlists";
import PlaylistModal from "./components/PlaylistModal";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal";

import styles from "./index.module.scss";

export const Context = createContext();

export default function PlaylistsPage() {
    const [device, setDevice] = useState([]);

    const [openModal, setOpenModal] = useState("");

    const [currentPlaylist, setCurrentPlaylist] = useState(null);

    const [openDropdown, setOpenDropdown] = useState(false);

    const [message, setMessage] = useState({
        text: "",
        type: false
    })

    const getPlaylists = () => REQUESTS.GET_PLAYLISTS(setDevice);

    const modalAction = (type, data) => {
        if (!type) return setOpenModal("");

        setOpenDropdown(null);

        const { is_protected } = data || {};

        if (type == "add") {
            setCurrentPlaylist(null);
        } else if (openModal?.current == "pin") {
            setCurrentPlaylist(data);
        } else if (type == "edit" || type == "delete") {
            setCurrentPlaylist(data);

            if (is_protected) {
                setOpenModal({
                    current: "pin",
                    next: type
                });
                return;
            }
        }
        setOpenModal(type);
    }

    const submitPin = (pin) => {
        setMessage({
            text: "",
            type: false
        })

        REQUESTS.PROTECTED_PLAYLIST({ id: currentPlaylist.id, pin }, (data) => {
            modalAction(openModal.next, data);
        }, (err) => {
            setMessage({
                text: err.message,
                type: false
            })
        });
    }

    useEffect(() => {
        getPlaylists();
    }, []);

    useEffect(() => {
        if (!openModal) {
            setMessage({
                text: "",
                type: false
            })
        }
    }, [openModal])

    return (
        <Context.Provider
            value={{
                openModal,
                //
                getPlaylists,
                //
                modalAction,
                //
                openDropdown,
                setOpenDropdown,
            }}
        >
            <div className={styles['playlists-page']}>
                <div className="wrapper">
                    <h1 className={styles['title']}><Text page='playlist' text='title' /></h1>
                    <div className={styles['device-info']}>
                        <div><span><Text page='playlist' text='mac_address' />: </span> {device.mac}</div>
                        <div><span><Text page='playlist' text='status' />: </span>
                            {
                                !device.payed
                                    ? device.free_trial < 2
                                        ? "Free trial"
                                        : "Free trial expired"
                                    : "Activated"
                            }
                        </div>
                        <div>
                            <span><Text page='playlist' text={device.payed ? "Activation expired" : "Free trial expired"} />: </span>
                            {device && device.payed ? device.activation_expired && dateFormat(device.activation_expired) : device.free_trial_expired && dateFormat(device.free_trial_expired)}</div>
                    </div>
                    <div className={styles['add-btn-container']}>
                        <Button onClick={() => { modalAction("add") }} styleType="grey" >{ICONS.ADD} <Text page="playlist" text="button_text" /></Button>
                    </div>
                    <Playlists playlists={device?.playlists || []} getPlaylists={getPlaylists} />
                </div>
                <PlaylistModal
                    mac={device.mac}
                    open={openModal == "add" || openModal == "edit"}
                    onClose={modalAction}
                    playlist={currentPlaylist}
                />
                <ConfirmDeleteModal
                    open={openModal == "delete"}
                    onClose={modalAction}
                    playlist={currentPlaylist}
                />
                <PinModal
                    open={openModal?.current == "pin"}
                    onClose={modalAction}
                    onSubmit={submitPin}
                    message={message}
                />
            </div>
        </Context.Provider>
    )
}
