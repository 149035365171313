
import classNames from "classnames";

import styles from "../styles/SectionResponsive.module.scss";

export default function SectionsResponsive({ sections }) {

    return (
        <>
            {
                sections.map((section, index) => {
                    const { title, description, img_left, img_right, translation_title_key, translation_description_key } = section;
                    return <section key={index} className={classNames(styles["section"], { [styles['second-bg']]: index % 2 !== 0 })}>
                        <div className={classNames("wrapper", styles['content'])}>
                            <div className={styles['texts']}>
                                <h2 className={styles['title']}>{title}</h2>
                                <div className={styles['text']} dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            <div className={styles['images']}>
                                <img className={styles['img']} src={img_left} alt="section-left" />
                                <img className={styles['img']} src={img_right} alt="section-right" />
                            </div>
                        </div>
                    </section>
                })
            }
        </>
    )
}
