import TEXT from '../../configs/text';

import useTranslation from '../../hooks/useTranslation';

import styles from "./index.module.scss";

export default function PrivacyPolicy() {

    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.privacy?.[text]) {
            return translation?.pages?.privacy?.[text];
        }

        return TEXT?.pages?.privacy?.[text];
    }

    return (
        <div className={styles['privacy-policy-page']}>
            <div className='wrapper'>
                <h1 className={styles['title']}>{handleText("page_name")}</h1>
                <div dangerouslySetInnerHTML={{ __html: handleText("content") }} />
            </div>
        </div>
    )
}
