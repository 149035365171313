import useTranslation from '../hooks/useTranslation';

import TEXT from '../configs/text';

export default function Text({ page, text }) {

    const translation = useTranslation();

    if (translation?.pages?.[page]?.[text]) {
        return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
}
