import { useEffect, useState } from "react";

import useStore from "./useStore";

export default function useTranslation() {
    const [translation, setTranslation] = useState([]);

    const [webSettings] = useStore("webSettings");
    const [selectedLanguage] = useStore("selectedLanguage");

    useEffect(() => {

        if (webSettings && webSettings.translations && selectedLanguage) {
            setTranslation(webSettings.translations[selectedLanguage.iso_code]);
        }

    }, [webSettings, selectedLanguage]);

    return translation;
}
