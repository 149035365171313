import axios from "axios";

const request = (
  method,
  url,
  body,
  callback,
  errorCallback,
  notTimeout,
  tokenByLocal = false,
  haveToken = true
) => {

  // let myAxios = axios.create();

  // if (!notTimeout) {
  //   myAxios.defaults.timeout = 30000;
  // }

  // if (haveToken) {
  //   myAxios.defaults.headers.common["Authorization"] = tokenByLocal
  //     ? `Bearer ${localStorage.getItem("TOKEN")}`
  //     : `Bearer ${sessionStorage.getItem("TOKEN")}`;
  // }

  // myAxios[method](url, method === "delete" ? { data: body } : body)

  const options = {
    url: url,
    method: method,
    data: body,
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": tokenByLocal
        ? `Bearer ${localStorage.getItem("TOKEN")}`
        : `Bearer ${sessionStorage.getItem("TOKEN")}`
    },
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
  }

  if (
    (url.endsWith("/editor/update_web_settings") && method.toLowerCase() === "put")
  ) {
    options.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  axios(options)
    .then((response) => {
      if (response.data.error) {
        if (response.data.message === "Forbiden" ||
          response.data.code == 401) {
          window.location.href = "#";
          localStorage.removeItem("TOKEN");
        }
        if (errorCallback) {
          errorCallback(response.data.message);
        }
      } else {
        if (response.data && response.data.message) {
          try {
            callback(response.data.message);
          } catch (e) {
            console.error("error in callback:", e.message);
          }
        }
      }
    })
    .catch((error) => {
      console.error(error);

      if (errorCallback) {
        if (error.response && error.response.data) {
          errorCallback(error.response.data);
        } else {
          window.location.href = "/#/under_construction";
        }
      }
    });
};

export default request;
