import { useEffect, useRef } from "react";

import classNames from "classnames";

import useTranslation from "../../../hooks/useTranslation";

import TEXT from "../../../configs/text";

import styles from "../index.module.scss";

export default function Sections({ sections }) {
    const translation = useTranslation();

    const sectionRef = useRef([]);

    const handleScroll = () => {
        const sections = sectionRef.current;

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const sectionTop = section.getBoundingClientRect().top;
            if (sectionTop < window.innerHeight / 2) {
                section.classList.add(styles['show']);
            } else {
                section.classList.remove(styles['show']);
            }
        }
    };

    const handleText = (text) => {
        if (translation?.pages?.home?.[text]) {
            return translation?.pages?.home?.[text];
        }

        return TEXT?.pages?.home?.[text];
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            {sections.map((section, index) => {
                const { title, description, img_left, img_right, translation_title_key, translation_description_key } = section;
                return <section ref={(el) => { sectionRef.current[index] = el }} key={index} className={classNames(styles["section"], { [styles['second-bg']]: index % 2 !== 0 })}>
                    <img src={img_left} alt="section-left" className={classNames(styles['img-left'], styles['img'])} />
                    <div className={classNames(styles["section-texts"])}>
                        <h4 className={styles['section-title']}>{title}</h4>
                        <div className={classNames(styles["section-texts"])} dangerouslySetInnerHTML={{ __html: handleText(translation_description_key) }} />
                    </div>
                    <img src={img_right} alt="section-right" className={classNames(styles['img-right'], styles['img'])} />
                </section>
            })}
        </>
    )
}
