export default function dateFormat(date) {
    const dateObj = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    const year = dateObj.getFullYear();

    month = month.toString().length === 1 ? `0${month}` : month;
    day = day.toString().length === 1 ? `0${day}` : day;

    return `${day}/${month}/${year}`;
}