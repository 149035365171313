import { useState } from "react";

import { Form, Formik, } from "formik";
import * as Yup from 'yup';

import useTranslation from "../../../hooks/useTranslation";

import REQUESTS from "../../../api/requests";
import TEXT from "../../../configs/text";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MessageModal from "../../../components/MessageModal";

import styles from "../index.module.scss";

export default function ContactForm({ }) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        text: '',
        type: false
    });

    const translation = useTranslation();

    const validationSchema = Yup.object({
        title: Yup.string().required(getTranslation("title_input_message")),
        email: Yup.string().email(getTranslation("email_input_validation_message")).required(getTranslation("email_input_message")),
        message: Yup.string().required(getTranslation("message_input_message"))
    });

    function getTranslation(text) {
        if (translation?.pages?.contact_us?.[text]) {
            return translation?.pages?.contact_us?.[text];
        }

        return TEXT?.pages?.contact_us?.[text];
    }

    const onSubmit = (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        setMessage({
            text: '',
            type: false
        });

        const body = {
            title: values.title,
            email: values.email,
            message: values.message,
        };

        function callback() {
            setLoading(false);
            resetForm();
            setSubmitting(false);
            setMessage({
                text: getTranslation("submition_success_message"),
                type: true
            })
        }

        function errorCallback(err) {
            setMessage({
                text: getTranslation("error_message"),
                type: false
            })
            setLoading(false);
        }

        REQUESTS.SEND_EMAIL(body, callback, errorCallback);
    };

    return <>
        <p className={styles['description']}>{getTranslation("description")}</p>
        <Formik
            initialValues={{ title: '', email: '', message: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >
            {props => {
                const {
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                } = props;

                return <Form autoComplete="off">
                    <Input
                        name="title"
                        placeholder={getTranslation("title_input_placeholder")}
                        message={{
                            text: touched.title && errors.title,
                            type: false
                        }}
                    />
                    <Input
                        name="email"
                        placeholder={getTranslation("email_input_placeholder")}
                        message={{
                            text: touched.email && errors.email,
                            type: false
                        }}
                    />
                    <Input
                        type="textarea"
                        name="message"
                        placeholder={getTranslation("message_input_placeholder")}
                        message={{
                            text: touched.message && errors.message,
                            type: false
                        }}
                    />
                    <Button type="submit" loading={loading} disabled={isSubmitting} >{getTranslation("button_title")}</Button>
                </Form>
            }}
        </Formik>
        <MessageModal
            open={message.text}
            message={message}
            onClose={() => setMessage({ text: '', type: false })}
        />
    </>
}
