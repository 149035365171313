
import { Link } from "react-router-dom";

import Text from "../../components/Text";

import ICONS from "../../configs/icons";

import img from "../../assets/images/macbooks.png";

import useStore from "../../hooks/useStore";

import ContactForm from "./components/ContactForm";

import styles from "./index.module.scss";

export default function ContactUsPage() {
    const [appInfo] = useStore("appInfo");
    const { phone, email } = appInfo;

    return (
        <div className={styles['contact-us-page']}>
            <div className="wrapper">
                <h1 className={styles['title']}><Text page="contact_us" text="title" /></h1>
                <div className={styles['content-container']}>
                    <div className={styles['form']}><ContactForm /></div>
                    <div className={styles['contacts-wrapper']}>
                        <div className={styles['contacts']}>
                            <Link to={phone ? `tel:${phone}` : ""} className={styles['contact']}>{ICONS.PHONE} <Text page="contact_us" text="phone_title" /></Link>
                            <Link to={email ? `mailto:${email}` : ""} className={styles['contact']}>{ICONS.CONTACT_EMAIL}<Text page="contact_us" text="email_title" /></Link>
                        </div>
                        <img src={img} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
