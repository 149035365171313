import React, { useRef } from 'react';

import NewsItem from './NewsItem';

import ICONS from '../../../configs/icons';

import styles from "../index.module.scss";

export default function News({ news }) {

    const newsListRef = useRef(null);
    const leftArrow = useRef(null);
    const rightArrow = useRef(null);

    const onClick = (direction) => {
        let newsRef = newsListRef?.current;

        if (!newsRef) return;

        let newsItemWidth = newsRef.children[0]?.offsetWidth + 34;
        let newsListWidth = newsRef.offsetWidth;
        let newsListScrollWidth = newsRef.scrollWidth;

        let scrollLeft = newsRef.scrollLeft + newsItemWidth * direction;

        if (scrollLeft < 0) {
            scrollLeft = 0;

            leftArrow.current.classList.add(styles['disbaled']);
            rightArrow.current.classList.remove(styles['disbaled']);

        } else if (scrollLeft > newsListScrollWidth - newsListWidth) {
            scrollLeft = newsListScrollWidth - newsListWidth;
            rightArrow.current.classList.add(styles['disbaled']);
            leftArrow.current.classList.remove(styles['disbaled']);
        } else {
            leftArrow.current.classList.remove(styles['disbaled']);
            rightArrow.current.classList.remove(styles['disbaled']);
        }

        newsRef.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
    return (
        <div className={styles['news-wrapper']}>
            <div className={styles['news-container']} ref={newsListRef}>
                {news.map((item, index) => <NewsItem key={index} news={item} />)}
            </div>
            <div className={styles['arrows']}>
                <div className={styles['left']} ref={leftArrow} onClick={() => onClick(-1)}>{ICONS.LEFT_ARROW}</div>
                <div className={styles['right']} ref={rightArrow} onClick={() => onClick(1)}>{ICONS.RIGHT_ARROW}</div>
            </div>
        </div>
    )
}
