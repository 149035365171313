import { useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import ICONS from '../../configs/icons';

import Button from '../../components/Button';

import Text from '../../components/Text';

import LanguageDropdown from './LanguageDropdown';
import Sidebar from './Sidebar';

import styles from "../styles/Header.module.scss";
import LogoutConfirmPopup from './LogoutConfirmPopup';

export default function Header() {
    const location = useLocation();

    const [openSidebar, setOpenSidebar] = useState(false);

    const [openLogoutPopup, setOpenLogoutPopup] = useState(false);

    const navigate = useNavigate();

    const menuItemClassName = (path) => {
        return classNames(
            styles['menu-item'],
            {
                [styles['active']]: location.pathname === path
            })
    }

    const logout = () => {
        localStorage.removeItem("TOKEN");
        navigate("/login");
        setOpenLogoutPopup(false);
    }

    return (
        <header className={styles['header']}>
            <div className={classNames(styles['content-block'], "wrapper")}>
                <Link to="/" className={styles['header-logo']} >{ICONS.LOGO_NAME}</Link>
                <ul className={styles['menu-list']}>
                    <li className={menuItemClassName("/")}><Link to="/"><Text page="home" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/activation")}><Link to="/activation"><Text page="activation" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/news")}><Link to="/news"><Text page="news" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/reseller")}><Link to="/reseller"><Text page="reseller" text="page_name" /></Link></li>
                </ul>
                <div className={styles['right-block']}>
                    <Link to="/contact-us"><Button styleType="white"><Text page="contact_us" text="page_name" /></Button></Link>
                    {localStorage.getItem("TOKEN") && location.pathname == "/playlists" && <Button styleType="grey" onClick={() => { setOpenLogoutPopup(true) }}><Text page="login" text="log_out" /></Button>}
                    <LanguageDropdown />
                </div>

                <div className={styles['burger-menu']} onClick={() => setOpenSidebar(true)}>{ICONS.MENU}</div>
            </div>
            <Sidebar open={openSidebar} onClose={() => setOpenSidebar(false)} />
            <LogoutConfirmPopup open={openLogoutPopup} onClose={() => { setOpenLogoutPopup(false) }} onConfirm={logout} />
        </header>
    )
}
