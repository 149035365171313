import { useContext } from 'react';

import classNames from 'classnames';

import REQUESTS from '../../../api/requests';

import Text from '../../../components/Text';
import Modal from '../../../components/Modal';

import { Context } from '../index';

import styles from "../styles/Modals.module.scss";

export default function ConfirmDeleteModal({ open, onClose, playlist }) {
    const { getPlaylists } = useContext(Context);

    const hanldeDelete = () => {
        if (!playlist) return;

        const body = { id: playlist.id };

        if (playlist.is_protected && playlist.pin) {
            body["pin"] = playlist.pin;
        }

        REQUESTS.PLAYLIST_DELETE(body, () => {
            getPlaylists();
            onClose();
        });
    }

    return <Modal
        open={open}
        onClose={onClose}
    >
        <p className={styles['modal-text']}><Text page="playlist" text="delete_playlist_modal_title" /></p>
        <div className={styles['btns-container']}>
            <button onClick={onClose} className={classNames(styles['cancel-btn'], styles['btn'])}><Text page="playlist" text="Cancel" /></button>
            <button onClick={hanldeDelete} className={classNames(styles['yes-btn'], styles['btn'])}><Text page="playlist" text="Yes" /></button>
        </div>
    </Modal>
}
