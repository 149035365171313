import classNames from "classnames";

import Loading from "./Loading";

import styles from "./styles/Button.module.scss";

export default function Button({ styleType = "default", loading, children, ...props }) {
    return <button
        className={classNames(styles['button'],
            { [styles['white']]: styleType == "white" },
            { [styles['grey']]: styleType == "grey" },
        )} {...props}>{loading && <Loading />} {children}</button>
}
