import classNames from 'classnames';

import Text from '../../../components/Text';

import ICONS from '../../../configs/icons';

import styles from "../styles/Package.module.scss";

export default function Package({ packageItem, onClick, active }) {
    const { name, schedule, price, description, id, currency } = packageItem;

    return (
        <div className={classNames(styles['package'], { [styles['active']]: active })} onClick={() => onClick(packageItem)}>
            <div className={styles['top']}>
                <div className={styles['name']}>{name}</div>
                <span>
                    {active && ICONS.CHECK_PACKAGE}
                </span>
            </div>

            <div className={styles['schedule-price-block']}>
                <p className={styles['schedule']}>{schedule}</p>
                <p className={styles['price']}>{price} {currency}</p>
            </div>
            <div className={styles['schedule-type']}>{schedule == "lifetime" ? <Text page='activation' text='one_time' /> : schedule == "year" ? <Text page='activation' text='yearly' /> : schedule}</div>
        </div>
    )
}
