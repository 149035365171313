
import classNames from "classnames";

import classes from "./styles/Message.module.scss";

export default function Message({ message, style }) {
    return (
        message && (
            <div className={classes[message.text ? "message-open" : "message-close"]} style={style}>
                <span
                    className={classNames({
                        [classes["success-text"]]: message.type,
                        [classes["error-text"]]: !message.type,
                    })}
                >
                    {message.text}
                </span>
            </div>
        )
    );
}
