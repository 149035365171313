import { createSlice } from "@reduxjs/toolkit";

const initialState = {
}

const webSettingsSlice = createSlice({
    name: 'webSettings',
    initialState,
    reducers: {
        setWebSettings: (state, action) => {
            return action.payload;
        },
    },
})

export const { setWebSettings } = webSettingsSlice.actions;

export default webSettingsSlice.reducer;