import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import REQUESTS from '../../../api/requests';

import dateFormat from '../../../utils/dateFormat';

import Text from '../../../components/Text';

import styles from "../index.module.scss";

export default function LastNews() {
    const [news, setNews] = useState([]);

    const getNews = () => {
        REQUESTS.GET_LAST_NEWS(
            (data) => {
                setNews(data.rows);
            },
        );
    };

    useEffect(() => {
        getNews();
    }, []);

    return (
        <section className={styles['latest-news-section']}>
            <div className={'wrapper'}>
                <h2 className={styles['news-section-title']}><Text page="home" text="news_section_title" /></h2>
                <div className={styles['news-container']}>
                    <div className={styles['news-list']}>
                        {news.map((item, index) => {
                            const { title, image, createdAt, id } = item || {};
                            return <Link to={`/news/${id}`} key={id} className={styles['single-news']}>
                                <img src={image} alt="news" className={styles['news-img']} />
                                <p className={styles['added-on']}>Added on :{" "}
                                    <time className={styles['time']}>{createdAt && dateFormat(createdAt)}</time>
                                </p>
                                <h5 className={styles['news-title']}>{title}</h5>
                            </Link>
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}
