import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import ReCAPTCHA from 'react-google-recaptcha';

import { Form, Formik } from "formik";
import * as Yup from 'yup';

import classNames from "classnames";

import Input from "../../components/Input";

import TEXT from "../../configs/text";

import Text from "../../components/Text";
import Button from "../../components/Button";
import Message from "../../components/Message";
import ValidateMacAddress from "../../components/ValidateMacAddress";

import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";

import styles from "./index.module.scss";

export default function LoginPage() {
    const [macIsValid, setMacIsValid] = useState(false);
    const [mac, setMac] = useState("");
    const [device, setDevice] = useState(null);
    const [captchaKey, setCaptchaKey] = useState(null);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [searchParams] = useSearchParams();

    const query = {
        mac: searchParams.get("mac"),
        code: searchParams.get("device_key"),
    }

    const translation = useTranslation();

    const navigate = useNavigate();

    const handleText = (text) => {
        if (translation?.pages?.login?.[text]) {
            return translation?.pages?.login?.[text];
        }
        return TEXT?.pages?.login?.[text];
    };

    const getCaptchaKey = () => REQUESTS.CAPTCHA.GET_SITE_KEY(setCaptchaKey);

    const onChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(null)
        );
    }

    const onSubmit = (values, { resetForm }) => {
        setErrorMessage({
            text: "",
            type: false,
        });

        const { code } = values;

        const body = {
            mac: device.mac,
        };

        if (device?.["auth_type"] == "device_key") {
            body["key"] = code.trim();
        } else {
            body["code"] = code.trim();
        }

        REQUESTS.LOGIN_BY_MAC(
            (data) => {
                localStorage.setItem("TOKEN", data);
                navigate("/playlists");
                resetForm();
            },
            (err) => {
                setErrorMessage({
                    text: err.message,
                    type: false,
                });
            },
            body
        );
    };

    useEffect(() => {
        const token = localStorage.getItem("TOKEN");

        getCaptchaKey();

        if (token) navigate("/playlists");

    }, [])

    useEffect(() => {
        if (query && query.mac || query.code) {
            setMac(query.mac);
        }
    }, [searchParams])

    return (
        <div className={styles['login-page']}>
            <div className={classNames("wrapper", styles['content'])}>
                <h1 className={styles['title']}><Text page="login" text="title" /></h1>
                <Formik Formik
                    enableReinitialize
                    initialValues={{
                        code: query.code || ""
                    }}
                    onSubmit={onSubmit}
                    validationSchema={
                        Yup.object({
                            code: Yup.string().required(handleText("device_key_input_message"))
                        })
                    }
                    validateOnBlur={false}
                >
                    {({ errors, touched, setFieldValue }) => {
                        return <div className={styles['login-form']}>
                            <Form autoComplete="off">
                                <ValidateMacAddress
                                    setMacIsValid={setMacIsValid}
                                    value={mac}
                                    setValue={setMac}
                                    setDevice={setDevice}
                                />

                                <Input
                                    name="code"
                                    placeholder={handleText("device_key")}
                                    message={{
                                        text: touched.code && errors.code,
                                        type: false
                                    }}
                                />

                                {captchaKey && <ReCAPTCHA sitekey={captchaKey} onChange={onChange} />}

                                <div className={styles['error-message']}>
                                    <Message message={errorMessage} style={{ fontSize: "1.6rem" }} />
                                </div>

                                <Button
                                    disabled={!macIsValid || (captchaKey ? !recaptchaValue : false)}
                                >
                                    <Text page="login" text="button_text" />
                                </Button>
                            </Form>
                        </div>
                    }}
                </Formik >
            </div>
        </div>
    )
}
