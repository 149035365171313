import { useState, useEffect } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import TEXT from "../../../configs/text";

import REQUESTS from "../../../api/requests";

import useTranslation from "../../../hooks/useTranslation";

import Text from "../../../components/Text";
import Input from "../../../components/Input";
import Modal from "../.././../components/Modal";
import Button from "../../../components/Button";
import Message from "../../../components/Message";
import MessageModal from "../../../components/MessageModal";
import ValidateMacAddress from "../../../components/ValidateMacAddress";

import styles from "../index.module.scss";

export default function ActivateByVoucherCode({ open, onClose }) {
    const [mac, setMac] = useState('');

    const [macIsValid, setMacIsValid] = useState(false);

    const [loading, setLoading] = useState(false);

    const [successMessage, setSuccessMessage] = useState({
        text: "",
        type: true
    });

    const [errorMessage, setErrorMessage] = useState({
        text: "",
        type: true
    });

    const translation = useTranslation()

    const handleText = (text) => {
        if (translation?.pages?.voucher_code?.[text]) {
            return translation?.pages?.voucher_code?.[text];
        }

        return TEXT?.pages?.voucher_code?.[text];
    };

    const validationSchema = Yup.object().shape({
        code: Yup.string().required(handleText("qa_modal_voucher_code_input_message"),),
    })

    const handleSubmit = (values) => {
        setLoading(true);

        setErrorMessage({
            type: false,
            text: ""
        });

        const { code } = values;

        const body = {
            code,
            mac: mac,
        };

        function callback() {
            setLoading(false);

            setSuccessMessage({
                type: true,
                text: handleText("qa_modal_voucher_code_success_message")
            });
        }

        function errorCallback(error) {
            setLoading(false);
            setErrorMessage({
                type: false,
                text: error.message
            });
        }

        REQUESTS.ACTIVATE_VOUCHER_CODE(body, callback, errorCallback);
    }

    useEffect(() => {
        if (!open) {
            setMac('')
            setMacIsValid(false)
            setErrorMessage({
                type: true,
                text: ""
            });
            setSuccessMessage({
                type: true,
                text: ""
            });
        }
    }, [open])

    return <>
        <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={{
                code: "",
            }}
        >
            {({ resetForm, values, errors, touched }) => (
                <Modal open={open} onClose={() => {
                    setMac('')
                    setMacIsValid(false)
                    onClose()
                    resetForm()
                }}>
                    <div className={styles['voucher-modal']}>
                        <h1 className={styles['title-voucher-activation']}><Text page="voucher_code" text="qa_modal_title" /></h1>
                        <Form autoComplete="off">
                            <ValidateMacAddress value={mac} setValue={setMac} setMacIsValid={setMacIsValid} />
                            <Input
                                name="code"
                                placeholder={handleText("qa_modal_voucher_code_input_placeholder")}
                                message={{
                                    type: false,
                                    text: touched.code && errors.code
                                }}
                            />
                            <div className={styles['message-text']}> <Message message={errorMessage} /></div>
                            <Button type="submit" styleType="grey" disabled={!macIsValid}><Text page="voucher_code" text="qa_modal_button_title" /></Button>
                        </Form>
                    </div>
                </Modal>
            )}
        </Formik >
        <MessageModal
            open={successMessage.text}
            message={successMessage}
            onClose={() => setSuccessMessage({
                text: "",
                type: true
            })} />
    </>
}
