import { useContext, useEffect, useRef, useState } from "react";

import classNames from "classnames";

import ICONS from "../../../configs/icons"

import useResize from "../../../hooks/useResize";

import { Context } from "../index";

import styles from "../styles/Playlists.module.scss";

export default function Actions({ open, playlist }) {
    const { modalAction, setOpenDropdown } = useContext(Context);

    useEffect(() => {
        document.addEventListener("click", (e) => {
            setOpenDropdown(null);
        })
    }, [open]);

    return <>
        <div className={classNames(styles['action-dropdown'], { [styles['show']]: open })} onClick={(e) => { e.stopPropagation() }} >
            <div className={styles['action-btn']} onClick={() => modalAction("edit", playlist)}>{ICONS.EDIT}</div>
            <div className={styles['action-btn']} onClick={() => modalAction("delete", playlist)}>{ICONS.TRASH}</div>
        </div>
    </>
}
