import { Link } from "react-router-dom";
import dateFormat from "../../../utils/dateFormat";

import styles from "../index.module.scss";

export default function NewsItem({ news }) {
    const { title, image, createdAt, id } = news || {};
    return (
        <Link to={`/news/${id}`} className={styles['single-news']}>
            <img src={image} alt="news" className={styles['news-img']} />
            <p className={styles['added-on']}>Added on :{" "}
                <time className={styles['time']}>{createdAt && dateFormat(createdAt)}</time>
            </p>
            <h5 className={styles['news-title']}>{title}</h5>
        </Link>
    )
}
