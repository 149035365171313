import React, { useEffect, useState } from 'react';

import REQUESTS from '../../api/requests';

import Text from '../../components/Text';

import News from './component/News';

import styles from "./index.module.scss";

export default function NewsPage() {
    const [news, setNews] = useState([]);

    const getNews = () => {
        REQUESTS.GET_NEWS((data) => setNews(data.rows));
    };

    useEffect(() => {
        getNews();
    }, []);

    return (
        <div className={styles['news-page']} >
            {news?.length && <>
                <h1 className={styles['title']}><Text page="news" text="title" /></h1>
                <News news={news} />
            </>
            }
        </div>
    )
}
