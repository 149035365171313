import React, { useRef } from 'react';

import { Link } from 'react-router-dom';

import ICONS from '../../../configs/icons';

import dateFormat from '../../../utils/dateFormat';

import styles from "../styles /NewsSlider.module.scss";

export default function NewsSlider({ news }) {

    const sliderRef = useRef(null);

    const leftArrow = useRef(null);
    const rightArrow = useRef(null);

    const scrollSlider = (direction) => {

        const slider = sliderRef.current;

        if (!slider) return;

        const scrollWidth = slider.scrollWidth;
        const clientWidth = slider.clientWidth;
        const elementWidth = slider.children[0].offsetWidth
        // console.log(slider.scrollLeft, 'slider.scrollLeft')

        slider.scrollLeft += elementWidth * direction;
        // console.log({ scrollWidth, clientWidth, elementWidth, scrolLeft: slider.scrollLeft });
    };


    const renderNews = () => {
        return news.map((item, index) => {
            return (
                <Link to={`/news/${item.id}`} key={index} className={styles['slide-news']}>
                    <div>{item.image && <img src={item.image} alt="news" className={styles['news-image']} />}</div>
                    <div className={styles['news-content']}>
                        <time className={styles['time']}>{item.createdAt && dateFormat(item.createdAt)}</time>
                        <h3 className={styles['title']}>{item.title}</h3>
                        <div className={styles['description']} dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                </Link>
            );
        });
    }
    return news && (
        <>
            <div className={styles['slider-container']}>
                <div className={styles['slider']} ref={sliderRef}>
                    {renderNews()}
                </div>
            </div>
            <div className={styles['arrows']}>
                <div className={styles['left']} ref={leftArrow} onClick={() => scrollSlider(-1)}>{ICONS.LEFT_ARROW}</div>
                <div className={styles['right']} ref={rightArrow} onClick={() => scrollSlider(1)}>{ICONS.RIGHT_ARROW}</div>
            </div>
        </>
    )
}
