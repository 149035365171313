import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';

import ReCAPTCHA from "react-google-recaptcha";

import Input from "../../components/Input";
import Button from "../../components/Button";
import Message from "../../components/Message";
import MessageModal from "../../components/MessageModal";

import TEXT from "../../configs/text";
import { RESELLER_PANEL } from "../../configs";

import REQUESTS from "../../api/requests";
import useTranslation from "../../hooks/useTranslation";

import PhoneInputComponent from "../../components/PhoneInputComponent";
import CustomSelect from "./component/CustomSelect";

import styles from "./index.module.scss";

export default function ResellerPage() {
    const [captchaKey, setCaptchaKey] = useState(null);

    const [isValid, setIsValid] = useState(false);

    const [loading, setLoading] = useState(false);

    const [aggreWithTerms, setAgreeWithTerms] = useState(false);

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const [successMessage, setSuccessMessage] = useState({
        type: true,
        text: "",
    });

    const translation = useTranslation();

    const validationSchema = Yup.object({
        name: Yup.string().required(getTranslation("name_input_message")),
        surname: Yup.string().required(getTranslation("surname_input_message")),
        company: Yup.string().required(getTranslation("company_input_message")),
        email: Yup.string().email(getTranslation("email_input_message")).required(getTranslation("")),
        password: Yup.string().min(8, getTranslation("password_length_message")).required(getTranslation("password_message")),
        country: Yup.string().required(getTranslation("country_select_message")),
        address: Yup.string().required(getTranslation("address_input_message")),
        phone: Yup.string().required(getTranslation("phone_input_message")),
    });

    function getTranslation(text) {
        if (translation?.pages?.register?.[text]) {
            return translation?.pages?.register?.[text];
        }
        return TEXT?.pages?.register?.[text];
    }

    const onSubmit = (values, { resetForm, setSubmitting }) => {

        setLoading(true);

        setMessage({
            type: false,
            text: "",
        });

        function callback(data) {
            resetForm();
            setLoading(false);
            setAgreeWithTerms(false);
            setSuccessMessage({
                type: true,
                text: getTranslation("Your registration is successfully done."),
            })
        }

        function errorCallback(error) {
            setMessage({
                type: false,
                text: error.message,
            })
            setLoading(false);
        }

        REQUESTS.RESELLER(values, callback, errorCallback);
    }

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (response) => setCaptchaKey(response),
            (err) => { setCaptchaKey(null); }
        );
    };

    function onChange(value) {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setIsValid(message.success);
            },
            (err) => setIsValid(false)
        );
    }

    useEffect(() => {
        getCaptchaSiteKey();
    }, []);

    return (
        <div className={styles['reseller-page']}>
            <div className="wrapper">
                <h1 className={styles['title']}>{getTranslation("title")}</h1>
                <Link to="/info-link" target="_blank" className={styles['subtitle']}>{getTranslation('subtitle')}</Link>
                <Formik
                    initialValues={{ name: "", surname: "", company: "", email: "", password: "", country: "", address: "", phone: "", is_referral: false, is_reseller: true }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                >
                    {props => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            errors,
                            touched,
                        } = props;
                        return <Form autoComplete="off">
                            <div className={styles['form-content']}>
                                <Input
                                    name="company"
                                    placeholder={getTranslation("company_input_placeholder")}
                                    message={{
                                        text: touched.company && errors.company,
                                        type: false
                                    }}
                                />
                                <div className={styles['twain']}>
                                    <Input
                                        name="name"
                                        placeholder={getTranslation("name_input_placeholder")}
                                        message={{
                                            text: touched.name && errors.name,
                                            type: false
                                        }}
                                    />
                                    <Input
                                        name="surname"
                                        placeholder={getTranslation("surname_input_placeholder")}
                                        message={{
                                            text: touched.surname && errors.surname,
                                            type: false
                                        }}
                                    />
                                </div>
                                <Input
                                    name="email"
                                    placeholder={getTranslation("email_input_placeholder")}
                                    message={{
                                        text: touched.email && errors.email,
                                        type: false
                                    }}
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder={getTranslation("password_input_placeholder")}
                                    message={{
                                        text: touched.password && errors.password,
                                        type: false
                                    }}
                                />
                                <div className={styles['twain']}>
                                    <Field
                                        name="country"
                                        component={CustomSelect}
                                    />
                                    <Input
                                        name="address"
                                        placeholder={getTranslation("address_input_placeholder")}
                                        message={{
                                            text: touched.address && errors.address,
                                            type: false
                                        }}
                                    />
                                </div>
                                <Field
                                    name="phone"
                                    component={PhoneInputComponent}
                                />
                                <div className={styles['form-bottom-section']}>
                                    <label className={styles['agree-text']}> {getTranslation("accept_text")} <input type="checkbox" checked={aggreWithTerms} onChange={() => setAgreeWithTerms(!aggreWithTerms)} /> {getTranslation("agree_text")}</label>
                                    {captchaKey && <ReCAPTCHA sitekey={captchaKey} onChange={onChange} />}
                                </div>
                                <div className={styles['message-container']}><Message message={message} /></div>
                                <Button loading={loading} type="submit" disabled={!aggreWithTerms || (captchaKey && !isValid) || loading} >{getTranslation("button_text")}</Button>
                                <p className={styles['login-text']}>If you already have an account, please <Link target="_blank" to={RESELLER_PANEL}>Login</Link>. </p>
                            </div>
                        </Form>
                    }}
                </Formik>
            </div>
            <MessageModal
                message={successMessage}
                open={successMessage.text}
                onClose={() => setSuccessMessage({ type: false, text: "" })}
            />
        </div>
    )
}
