import classNames from 'classnames';

import Message from './Message';

import styles from './styles/Input.module.scss';

export default function UploadInput({ file, message, onChange, ...props }) {
    return (
        <div className={styles["m3u8-file-input"]} placeholder="test">
            <div className={classNames(styles["file-txt"], { [styles['error']]: message?.text?.length })}>
                {
                    file && file.name ?
                        <div className={styles["file-name"]}>{file.name}</div>
                        :
                        <div>m3u8</div>
                }
                <input
                    type='file'
                    accept=".m3u8,.m3u"
                    onChange={onChange}
                    {...props}
                />
            </div>
            <Message message={message} />
        </div>
    )
}
