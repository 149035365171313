import { useState } from "react";

import { createPortal } from 'react-dom';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import Text from '../../components/Text';
import Button from '../../components/Button';

import ICONS from '../../configs/icons';

import LogoutConfirmPopup from './LogoutConfirmPopup';
import LanguageDropdown from './LanguageDropdown';

import styles from "../styles/Sidebar.module.scss";

export default function Sidebar({ open, onClose }) {
    const location = useLocation();

    const navigate = useNavigate();

    const [openLogoutPopup, setOpenLogoutPopup] = useState(false);

    const handleClose = (e) => {
        if (e.target.classList.contains(styles["sidebar"])) {
            onClose();
        }
    }

    const menuItemClassName = (path) => {
        return classNames(
            styles['menu-item'],
            {
                [styles['active']]: location.pathname === path
            })
    };

    const logout = () => {
        localStorage.removeItem("TOKEN");
        navigate("/login");
        setOpenLogoutPopup(false);
        onClose();
    }

    return createPortal(
        <div className={classNames(styles['sidebar'], { [styles['show']]: open })} onClick={handleClose}>
            <div className={classNames(styles['sidebar-content'], { [styles['show']]: open })}>
                <div className={styles['top-block']}>
                    <div className={styles['logo']} >{ICONS.LOGO_NAME}</div>
                    <div className={styles['close']} onClick={onClose}>{ICONS.CLOSE}</div>
                </div>
                <ul className={styles['menu-list']}>
                    <li className={menuItemClassName("/")}><Link to="/" onClick={onClose}><Text page="home" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/activation")}><Link to="/activation" onClick={onClose}><Text page="activation" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/news")}><Link to="/news" onClick={onClose}><Text page="news" text="page_name" /></Link></li>
                    <li className={menuItemClassName("/reseller")}><Link to="/reseller" onClick={onClose}><Text page="reseller" text="page_name" /></Link></li>
                    <li className={menuItemClassName()}>
                        <Link to="/contact-us" onClick={onClose} className={styles['contact-us-btn']}><Button styleType="white" ><Text page="contact_us" text="page_name" /></Button></Link>
                    </li>
                    <li className={menuItemClassName()}><LanguageDropdown placement="sidebar" /></li>
                    {localStorage.getItem("TOKEN") && location.pathname == "/playlists" &&
                        <li className={menuItemClassName()} onClick={() => { setOpenLogoutPopup(true) }}>
                            <Text page="login" text="log_out" /> {ICONS.LOGOUT}
                        </li>}
                </ul>
            </div>
            <LogoutConfirmPopup open={openLogoutPopup} onClose={() => { setOpenLogoutPopup(false) }} onConfirm={logout} />
        </div>,
        document.body
    )
}
