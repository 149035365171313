import { configureStore } from '@reduxjs/toolkit';
import languageSlice from './feautures/languageSlice';
import webSettingsSlice from './feautures/webSettingsSlice';
import setAppInfoSlice from './feautures/appInfoSlice';

export const store = configureStore({
    reducer: {
        selectedLanguage: languageSlice,
        webSettings: webSettingsSlice,
        appInfo: setAppInfoSlice
    },
});

export default store;
