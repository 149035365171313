import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import REQUESTS from '../../api/requests';

import { setLanguage } from '../../store/feautures/languageSlice';

import useStore from '../../hooks/useStore';

import styles from "../styles/Language.module.scss";

export default function LanguageDropdown({ placement }) {

    const [languages, setLanguages] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useStore("selectedLanguage");

    const [isActive, setIsActive] = useState();

    const getLanguages = () => {
        function callback(data) {

            setLanguages(data);

            const languageCodeFromLocalStorage = localStorage.getItem("LANGUAGE_CODE");

            if (languageCodeFromLocalStorage) {
                const active = data.find((item) => item.iso_code === languageCodeFromLocalStorage);

                if (active) {
                    setSelectedLanguage(setLanguage(active));
                    setIsActive(active);
                }

            } else {
                const activeLanguage = data.find((item) => item.is_default);
                setIsActive(activeLanguage);
                setSelectedLanguage(setLanguage(activeLanguage));
            }
        }

        REQUESTS.LANGUAGES(callback);
    };

    const onChange = (selected) => {
        if (languages) {
            setSelectedLanguage(setLanguage(selected));
            setIsActive(languages.find((item) => item.id === selected.id));
        }
    };

    useEffect(() => {
        if (isActive) {
            localStorage.setItem("LANGUAGE_CODE", isActive.iso_code || "en");
        }
    }, [isActive]);

    useEffect(() => {
        getLanguages();
    }, []);

    return <div className={classNames(styles["dropdown"], { [styles['in-sidebar']]: placement == "sidebar" })}>
        {isActive && <button className={styles["dropdown-toggle"]} >
            <img src={isActive.icon} alt="active language" className={styles['active-lang-flag']} /> {isActive.name}
        </button>}
        <ul className={styles["dropdown-menu"]} >
            {languages.map((item) => (
                <li key={item.id} onClick={() => onChange(item)} className={styles["dropdown-item"]}>
                    <img src={item.icon} alt="active language" className={styles['lang-flag']} /> {item.name}
                </li>
            ))}
        </ul>
    </div>
}
