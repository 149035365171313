
import { Form, Formik } from "formik";
import * as Yup from 'yup';

import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import Button from "../../../components/Button";
import Text from "../../../components/Text";

export default function EmailModal({ pay, open, onClose }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Formik
                initialValues={{ name: "", email: "" }}
                validationSchema={Yup.object({
                    name: open == "cmi" ? Yup.string().required("Required") : Yup.string(),
                    email: Yup.string().email("Invalid email address").required("Required"),
                })}
                onSubmit={(values, { isSubmitting }) => {
                    const { name, email } = values;
                    pay(email, name);
                }}
                validateOnBlur={false}
            >
                {({ isSubmitting, resetForm, errors, touched, values }) => {
                    const { name, email } = values;
                    const disabled = open == "cmi" ? !name || !email : !email;

                    return <Form autoComplete="off">
                        {open == "cmi" &&
                            <Input
                                type="text"
                                name="name"
                                placeholder="Name"
                                message={{
                                    text: touched.name && errors.name,
                                    type: false
                                }}
                            />}
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            message={{
                                text: touched.email && errors.email,
                                type: false
                            }}
                        />
                        <Button type="submit" disabled={disabled}><Text page="activation" text="email_modal_button_text" /></Button>
                    </Form>
                }}
            </Formik>
        </Modal>
    )
}
