import React from 'react'

import Package from './Package'

import styles from "../styles/Package.module.scss";

export default function Packages({ packages = [], currency, onClick, selectedPackage = {} }) {
    return <div className={styles['packages']}>
        {packages.map((item, index) => <Package key={index} packageItem={{ ...item, currency, }} onClick={onClick} active={selectedPackage.id == item.id} />)}
    </div>
}
