
import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../configs/text";

import ICONS from "../../configs/icons";

import styles from "./index.module.scss";

export default function PaymentSuccess() {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.payment_success?.[text]) {
            return translation?.pages?.payment_success?.[text];
        }

        return TEXT?.pages?.payment_success?.[text];
    };

    return (
        <div className={styles['payment-success-page']}>
            <div className={styles['message-container']}>
                {ICONS.SUCCESS_CHECK}
                <h1 className={styles['title']}>{handleText('page_name')}</h1>
                <span className={styles['message']}>{handleText('message')}</span>
            </div>
        </div>
    )
}
