import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import IMAGES from '../configs/images';

import styles from "./styles/Layout.module.scss";

export default function Layout() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            <div className={styles['social-icons']}>
                <a href='https://t.me/RelaxPlaySupport' target='_blank'>
                    <img src={IMAGES.TELEGRAM} alt='telegram icon' className={styles['social-icon-img']} />
                </a>
                <a href="mailto:relaxplay@admin.com">
                    <img src={IMAGES.EMAIL} alt="email icon" className={styles['social-icon-img']} />
                </a>
            </div>
        </>
    )
}
