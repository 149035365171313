import React, { useState, useEffect } from 'react';

import { useField } from 'formik';

import classNames from 'classnames';

import Message from '../../../components/Message';

import styles from "../index.module.scss";

export default function CustomSelect({ ...props }) {

    const [countries, setCountries] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const [field, meta, helpers] = useField({ name: "country", ...props });

    const [selectedCountry, setSelectedCountry] = useState(field.value);

    const handleSelect = (country) => {
        helpers.setValue(country.name);  // Update the Formik value
        helpers.setTouched(true); // Set the field as touched
        setSelectedCountry(country);
        setIsOpen(false);
    };

    const getCountries = () => {
        fetch("https://restcountries.com/v3.1/all?fields=name,flags")
            .then(res => res.json())
            .then(data => {
                const c = data.map(item => {
                    return {
                        name: item.name.common,
                        flag: item.flags.png
                    }
                })
                setCountries(c)
            })
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (event) => {
        if (event.target.closest(`.${styles["dropdown-options"]}`) === null && event.target.closest(`.${styles["dropdown-label"]}`) === null) {
            setIsOpen(false);
        }
    };

    useEffect(() => {

        getCountries();

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    useEffect(() => {
        if (field && field.value == "") {
            setSelectedCountry("")
        }
    }, [field])

    return (
        <div className={styles["custom-dropdown"]}>
            {selectedCountry ? <div className={classNames(styles["dropdown-label"], { [styles['error']]: meta.touched && meta?.error?.length })} onClick={toggleDropdown} >
                <img alt={selectedCountry.name} src={selectedCountry.flag} className={styles['flag']} /> {selectedCountry.name}
            </div>
                : <div className={classNames(styles["dropdown-label"], { [styles['error']]: meta.touched && meta?.error?.length })} onClick={toggleDropdown}>Select a Country</div>
            }
            <div className={classNames(styles["dropdown-options"], { [styles['show']]: isOpen })}>
                {countries.map((country, i) => (
                    <div
                        key={i}
                        className={styles["dropdown-option"]}
                        onClick={(e) => {
                            handleSelect(country)
                        }}
                    >
                        <img alt={country.name} src={country.flag} className={styles['flag']} /> {country.name}
                    </div>
                ))}
            </div>
            <Message message={{ text: meta.touched && meta?.error, type: false }} />
        </div>
    )
}
