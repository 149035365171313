import { useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from 'yup';

import useTranslation from "../../../hooks/useTranslation";

import TEXT from "../../../configs/text";

import REQUESTS from "../../../api/requests";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Message from "../../../components/Message";
import UploadInput from "../../../components/UploadInput";

import styles from "../styles/PlaylistModal.module.scss";

export default function PlaylistByFile({ mac, getPlaylists }) {
    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState(null);

    const [errorMessage, setErrorMessage] = useState({
        text: null,
        type: false
    });

    const translation = useTranslation();

    const handleChange = event => {
        const f = event.currentTarget.files[0];
        setFile(f);
    };

    const handleText = (text) => {
        if (translation?.pages?.playlist?.[text]) {
            return translation?.pages?.playlist?.[text];
        }

        return TEXT?.pages?.playlist?.[text];
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(handleText("playlist_name_message")),
        image: Yup.string().required(handleText("playlist_file_message")),
        is_protected: Yup.boolean(),
        pin: Yup.string().test('pin', handleText("Pin is required!"), function (value) {
            if (this.parent.is_protected) {
                return value ? true : false;
            }

            return true;
        }),
        confirm_pin: Yup.string().test('confirm_pin', handleText("Confirm Pin is required!"), function (value) {
            if (this.parent.is_protected) {
                return value ? true : false;
            }
            return true;
        }).test('confirm_pin', handleText("Pins must match!"), function (value) {
            if (this.parent.is_protected) {
                return value === this.parent.pin;
            }
            return true;
        })
    });

    const onSubmit = (values) => {

        setErrorMessage({
            text: null,
            type: false
        });

        setLoading(true);

        const { name, pin, confirm_pin, epg_url, image, is_protected } = values;

        const formData = new FormData();

        formData.append("name", name);

        formData.append("mac", mac);

        if (image && file) {
            formData.append("image", file);
        }

        formData.append("is_protected", is_protected);

        if (is_protected) {
            formData.append("pin", pin);
            formData.append("confirm_pin", confirm_pin);
        }

        if (epg_url) {
            formData.append("epg_url", epg_url.split(" ").join(""));
        }

        function callback(data) {
            setLoading(false);
            setFile(null);
            getPlaylists();
        }

        function errorCallback(error) {
            setErrorMessage({
                text: error.message,
                type: false
            });
            setLoading(false);
        }

        REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
    };

    return (
        <Formik
            initialValues={{
                name: "",
                image: "",
                epg_url: "",
                pin: "",
                confirm_pin: "",
                is_protected: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            destroyOnUnmount={true}
        >
            {({ values, errors, touched, setFieldValue, resetForm }) => {
                return <Form autoComplete="off">
                    <Input
                        name="name"
                        placeholder={handleText("playlist_name")}
                        message={{
                            text: touched.name && errors.name,
                            type: false
                        }}
                    />
                    <UploadInput
                        name="image"
                        message={{ type: false, text: touched.image && errors.image }}
                        file={file}
                        onChange={(e) => {
                            handleChange(e);
                            setFieldValue('image', e.currentTarget.files[0]);
                        }}
                    />
                    <Input
                        name="epg_url"
                        placeholder={handleText("playlist_epg_url")}
                    />

                    <div className={styles['protected-checkbox']}>
                        <Input
                            name="is_protected"
                            type="checkbox"
                            label={handleText("protect_playlist")}
                        />
                    </div>

                    <Input
                        type="password"
                        name="pin"
                        placeholder={handleText("set_pin")}
                        disabled={!values.is_protected}
                        message={{
                            text: touched.pin && errors.pin,
                            type: false
                        }}
                    />
                    <Input
                        type="password"
                        name="confirm_pin"
                        placeholder={handleText("confirm_pin")}
                        disabled={!values.is_protected}
                        message={{
                            text: touched.confirm_pin && errors.confirm_pin,
                            type: false
                        }}
                    />
                    <div className={styles['err-message-container']}><Message message={errorMessage} style={{ fontSize: 16 }} /></div>
                    <Button loading={loading} disabled={loading} type="submit">{handleText("url_button_title")}</Button>
                </Form>
            }}
        </Formik >
    )
}
